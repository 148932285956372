<template>
  <div class="model-platform-data">
    <Collapse title="Live Jasmin">
      <div class="model-platform-data-input">
        <label>Fecha de creación</label>
        <input type="date" name="jasminCreationDate" :value="$global.toInputDate(model.jasminCreationDate)" />
      </div>
      <div class="model-platform-data-input">
        <label>Fecha de inicio transmisión</label>
        <input type="date" name="jasminStreamDate" :value="$global.toInputDate(model.jasminStreamDate)" />
      </div>
      <div class="model-platform-data-input">
        <label class="label">
          <span>Welcome Bonus</span>
          <ToggleSwitch name="jasminWelcomeBonus" @checked="onIsBonus" :checked="model.jasminWelcomeBonus === 'on'" />
        </label>
        <div class="is_margin" v-if="isBonus">
          <div class="range-picker range-input flex-between" @click="$refs.myDatePicker.togglePicker()">
            <div class="range-picker-from">
              <input type="date" name="jasminBonusDateFrom" :value="$global.toInputDate(jasminBonusDateFrom)" readonly class="input" />
            </div>
            <div class="range-picker-middle">-</div>
            <div class="range-picker-to">
              <input type="date" name="jasminBonusDateTo" :value="$global.toInputDate(jasminBonusDateTo)" readonly class="input" />
            </div>
          </div>
        </div>
      </div>
    </Collapse>
    <v-md-date-range-picker
      v-if="isBonus"
      class="platform-range"
      ref="myDatePicker"
      @change="handleDateBonus"
      :start-date="$global.toInputDate(jasminBonusDateFrom)"
      :end-date="$global.toInputDate(jasminBonusDateTo)"
    ></v-md-date-range-picker>
    <Collapse title="Streamate">
      <div class="model-platform-data-input">
        <label>Fecha de creación</label>
        <input type="date" name="streamateCreationDate" :value="$global.toInputDate(model.streamateCreationDate)" />
      </div>
      <div class="model-platform-data-input">
        <label>Fecha de inicio transmisión</label>
        <input type="date" name="streamateStreamDate" :value="$global.toInputDate(model.streamateStreamDate)" />
      </div>
    </Collapse>
  </div>
</template>

<script>
export default {
  props: ["model"],
  components: { Collapse: () => import("../widgets/Collapse.vue"), ToggleSwitch: () => import("../inputs/ToggleSwitch.vue") },
  data: function () {
    return {
      isBonus: this.model.jasminWelcomeBonus === "on",
      jasminBonusDateFrom: this.model.jasminBonusDateFrom || this.$global.currentYMD(),
      jasminBonusDateTo: this.model.jasminBonusDateTo || this.$global.currentYMD(),
    };
  },
  methods: {
    onIsBonus: function (isBonus) {
      this.isBonus = isBonus;
    },
    handleDateBonus: function (dateFull, dateSingle) {
      this.jasminBonusDateFrom = dateSingle[0];
      this.jasminBonusDateTo = dateSingle[1];
    },
  },
};
</script>

<style lang="scss">
.model-platform-data {
  background: #fff;
  border-radius: $mradius/2;
  border: solid 1px $alto;
  overflow: hidden;
  .collapse {
    &:not(:first-child) {
      border-top: solid 1px $alto;
    }
    &-header {
      padding: $mpadding/2 0 $mpadding/2 $mpadding/1.5;
    }
  }
  &-input {
    border-top: solid 1px $alto;
    padding: $mpadding;
    background: rgb(245, 245, 245);
    .is_margin {
      margin: $mpadding/2 0 0 0;
    }
    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .toggle-switch {
        width: 40px;
        height: 20px;
        &::before {
          top: 0;
          left: 0;
        }
      }
      .toggle-checkbox:checked + .toggle-switch:before {
        left: 20px;
      }
    }
    .range-picker {
      &.range-input {
        position: relative;
        user-select: none;
        background: #fff;
        border-radius: $mradius/2;
        border: solid 1px $alto;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      &-from,
      &-to {
        width: 50%;
        input {
          display: grid;
          max-height: 37px;
          &[type*="date"].input {
            border: none;
            user-select: none;
          }
        }
      }
      &-from {
        input {
          text-align: right;
          justify-content: flex-end;
        }
      }
      &-to {
        input {
          text-align: left;
          justify-content: flex-start;
        }
      }
      &-middle {
        display: flex;
        align-items: center;
        font-size: 180%;
      }
    }
  }
  .mdrp-root {
    margin-top: -$mpadding * 3.5;
    &.platform-range {
      position: absolute;
    }
    .mdrp__activator {
      display: none;
    }
    .calendar-table.calendar {
      &.right {
        display: none;
      }
      &.left {
        padding: $mpadding/2;
      }
    }
  }
}
</style>
